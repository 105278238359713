<template>
   <ContentWrapper :fullHeight="true">
    <template v-slot:title>Users</template>
    <router-view></router-view>
  </ContentWrapper>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  @include respond(phone){
    .user-wrapper{
      height: calc(100vh - 60px - 58px);
    }
  }
</style>